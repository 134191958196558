import Link from "next/link";

function Custom404() {
  return (
    <section className="flex items-center h-full p-16">
      <div className="container flex flex-col items-center justify-center px-5 mx-auto my-8">
        <div className="text-center font-nunitoBold">
          <h2 className="mb-8 font-extrabold text-9xl text-gray-600">
            <span className="sr-only">Error</span>404
          </h2>
          <p className="text-2xl md:text-3xl text-gray-800">
            Oooops, Aparentemente essa página não existe!
          </p>
          <p className="text-xl mt-4 mb-8 text-gray-800">
            Mas não se preocupe, você vai encontrar o que deseja :)
          </p>
          <Link href="/dashboard" passHref prefetch={false}>
            <a className="px-8 py-3 font-semibold rounded bg-blue-400 text-gray-700">
              Voltar a página
            </a>
          </Link>
        </div>
      </div>
    </section>
  );
}

export default Custom404;
